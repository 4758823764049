<template>
      <div class="d-flex flex-column justify-center page-height background-1">
        <v-container class="authentication-container">
        <v-row>
          <v-col xs="12" class="pb-0"> 
            <span class="app-title">Change Password</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" class="pt-6 pb-0">
            <span class="color-theme-3"><b>Please key in your password</b></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0 text-input">
            <v-text-field
            v-model="form.currentPassword"
            label="Current Password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[(!!form.currentPassword) || 'Required']"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            class="password"
            background-color="white"
            @click:append="show = !show"
            @keydown.enter="formSubmit"
          ></v-text-field>
          </v-col>
        </v-row>      
        <v-row>
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0 text-input">
            <v-text-field
            v-model="form.newPassword"
            label="New Password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[(form.currentPassword !== form.newPassword) || 'Same password detected']"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            class="password"
            background-color="white"
            @click:append="show2 = !show2"
            @keydown.enter="formSubmit"
          ></v-text-field>
          </v-col>
        </v-row>        
        <v-row>
          <v-col xs="12" class="pt-0">
            <v-btn
              rounded
              class="login-button"
              @click="formSubmit"
            ><span>Change Password</span></v-btn>
          </v-col>
        </v-row>       
        <v-row>
          <v-col xs="12" class="pt-0">
            <router-link to="/profile">
              <span class="text-underline color-theme-1">Cancel</span>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      </div>
</template>

<script>
import { openRoadApiUrl, version } from "/src/constants/config";
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';

export default {
  mixins: [currentUserMixin],
  data() {
    return {
        form: {
            currentPassword: "", 
            newPassword: "",
        },
        show: false,
        show2: false,
        rules: [
          value => !!value || 'Required.',
        ],
    };
  },
    
    methods: {
        formSubmit() { 
            let form = {
                currentPassword: this.form.currentPassword,
                newPassword: this.form.newPassword,
            }

            let url = openRoadApiUrl + "/api/services/" + version + "/user/me/changepassword";
                axios.put(url, form, this.$data.apiHeaders)
                        .then(
                        response => {     
                            this.$notify("success", "Change Password Success", "", {
                            duration: 6000,
                            permanent: false
                            });
                            this.$router.push("/profile");
                },
                error => {
                    let message = "Failed";
                    let details = error && error.response && error.response.data.error 
                                  ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                                  : "";
                    this.$notify("failed", message, details, {
                    duration: 6000,
                    permanent: false
                    });
                }
            )
        }
    },
    
  components: {
  },
  watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
