<template>
      <div class="d-flex flex-column justify-center page-height background-1">
        <v-container class="authentication-container">
        <v-row>
          <v-col xs="12" class="pb-0"> 
            <span class="app-title">Forgot Password</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" class="pt-6 pb-0">
            <span class="color-theme-3"><b>Please key in your email address</b></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0  text-input">
            <v-text-field
              v-model="form.email"
              label="Email address"
              :rules="rules"
              hide-details="auto"
              background-color="white"
              color="gray"
              @keydown.enter="formSubmit"
            ></v-text-field>
          </v-col>
        </v-row>        
        <v-row>
          <v-col xs="12" class="pt-0">
            <v-btn
              rounded
              class="login-button"
              @click="formSubmit"
            ><span>Reset Password</span></v-btn>
          </v-col>
        </v-row>       
        <v-row>
          <v-col xs="12" class="pt-0">
            <router-link to="/">
              <span class="text-underline color-theme-1">Cancel</span>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      </div>
</template>

<script>
import { openRoadApiUrl, version } from "/src/constants/config";
import axios from 'axios';
export default {
  data() {
    return {
        form: {
            email: "", 
        },
        show: false,

        rules: [
          value => !!value || 'Required.',
        ],
    };
  },
    
    methods: {
        formSubmit() { 
          let url = openRoadApiUrl + "/api/services/" + version + "/user/me/forgotpassword";
              let params = {                
                  email: this.form.email,
              };
              axios.post(url, params )
              .then(
                  response => {    
                      this.$notify("success", "Reset Password Success", "Please check your email.", {
                      duration: 6000,
                      permanent: false
                      });
                  },
                  error => {
                      let message = "Failed";
                    let details = error && error.response && error.response.data.error 
                                  ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                                  : "";
                      this.$notify("failed", message, details, {
                      duration: 6000,
                      permanent: false
                      });
                  }
              ) 
        }
    },
    
  components: {
  },
  watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
