<template>
      <div class="d-flex flex-column justify-center page-height background-1">
        <v-container class="authentication-container">
        <v-row>
          <v-col xs="12" class="pb-0"> 
            <span class="app-title">Reset Password</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" class="pt-6 pb-0">
            <span class="color-theme-3"><b>Please key in your new password</b></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0 text-input">
            <v-text-field
            v-model="form.password"
            label="New password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[(!!form.password) || 'Password must match']"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            class="password"
            background-color="white"
            @click:append="show = !show"
            @keydown.enter="formSubmit"
          ></v-text-field>
          </v-col>
        </v-row>      
        <v-row>
          <v-col xs="12" lg="3" sm="6" class="pt-0 pb-0 text-input">
            <v-text-field
            v-model="form.confirmPassword"
            label="Confirm new password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[(form.password === form.confirmPassword) || 'Password must match']"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            value="wqfasds"
            class="password"
            background-color="white"
            @click:append="show2 = !show2"
            @keydown.enter="formSubmit"
          ></v-text-field>
          </v-col>
        </v-row>        
        <v-row>
          <v-col xs="12" class="pt-0">
            <v-btn
              rounded
              class="login-button"
              @click="formSubmit"
            ><span>Reset Password</span></v-btn>
          </v-col>
        </v-row>       
        <v-row>
          <v-col xs="12" class="pt-0">
            <router-link to="/">
              <span class="text-underline color-theme-1">Cancel</span>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      </div>
</template>

<script>
import { openRoadApiUrl, version } from "/src/constants/config";
import axios from 'axios';

export default {
  data() {
    return {
        form: {
            password: "", 
            confirmPassword: "",
        },
        show: false,
        show2: false,
        rules: [
          value => !!value || 'Required.',
        ],
    };
  },
    
    methods: {
        formSubmit() { 

            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            let email =params.get("email");
            let code =params.get("code");

            let form = {
                code: code,
                email: email,
                password: this.form.password
            }

            let url = openRoadApiUrl + "/api/services/" + version + "/user/me/setpassword";
                axios.put(url, form)
                        .then(
                        response => {     
                            this.$notify("success", "Reset Password Success", "", {
                            duration: 6000,
                            permanent: false
                            });
                            this.$router.push("/login");
                },
                error => {
                    let message = "Failed";
                    let details = error && error.response && error.response.data.error 
                                  ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                                  : "";
                    this.$notify("failed", message, details, {
                    duration: 6000,
                    permanent: false
                    });
                }
            )
        }
    },
    
  components: {
  },
  watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
